import React, { useContext, useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import Router from 'next/router';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';

import { debounce } from 'lodash';
import nameValidationWithError from '../helpers/nameValidationWithError';
import emailValidationWithError from '../helpers/emailValidationWithError';
import loadScript from '../helpers/loadScript';
import { getFullDate } from '../helpers/timeHelpers';
import getCardType from '../helpers/getCardType';
import SITE_TYPE from '../constants/siteType';
import { SCHEDULE_TYPE_ASAP_PRIORITY, SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';
import { CARD, OTHER_CARD, HYPUR, STRONGHOLD, KIND_TAP, LEDGERGREEN, COD, SWIFTER } from '../constants/paymentTypes';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import { useCartConcentration, useHyperURL, useUpsellProducts } from '../hooks/cart';
import useSegment from '../hooks/segment';

import useAPI from '../NetworkCall/API';
import useSocket from '../NetworkCall/Socket';

import { useCardList, useIdProof, useKindTapList, useStrongholdList } from '../hooks/User';
import { useDeliveryDetails, useHexAsapDeliveryDetails } from '../hooks/app';
import StorageService from '../services/storageService';
import APIException from '../services/APIException';
import appContext from '../Context/appContext';
// import appConfig from '../appConfig';

import { DEFAULT_ADDRESS, DEFAULT_DATETIME } from '../constants/default';
import { isScheduleDropDownSet } from '../constants/cookieVariables';
import HypurVerificationPopup from './modalNotification/HypurVerificationPopup';
import { setRollbarWarning } from '../helpers/rollBar';
import LedgerGreenModal from './Modal';
import LedgerGreenVerificationPopup from './modalNotification/LedgerGreenVerificationPopup';
import { defaultCardData } from '../reducers/cart';
import envAppConfig from '../constants/envAppConfig';
import { KIVA } from '../constants/website';
import { useDispatch, useSelector } from 'react-redux';
import { setCartData, setCustomerInfo, setLedgerGreenCardData } from '../redux/slices/cartSlice';
import { getSessionID } from '../NetworkCall/APIs';
import { useConfigData } from '../Context/ConfigProvider';

const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

const BringItButton = ({ buttonText, setLoading, pathTo, setCheckingOut, host }) => {
  const {
    dispatch,
    state: {
      // user: { couponData },
      // location,
      // userID,
      // user: {
      //   isLoggedIn,
      //   deliveryDetails: {
      //     deliveryTypeSelected,
      //     deliveryDate,
      //     deliveryTime,
      //     deliveryDateTime,
      //     address,
      //     latitude,
      //     longitude,
      //     postcode,
      //     state,
      //     city,
      //     country
      //   },
      //   smsCheckbox,
      //   smsMessage,
      //   userInfo: { phone, country: country_code }
      // },
      // cart: {
      //   updatingProductsList,
      //   cartDetails,
      //   checkoutPossible,
      //   complianceFee,
      //   customerData: {
      //     name: cartName,
      //     first_name,
      //     last_name,
      //     email,
      //     isNewCard,
      //     isLedgerGreenNewCard,
      //     ledgerGreenSelectedCard,
      //     paymentType,
      //     cardData,
      //     notes,
      //     useWalletAmount,
      //     useGiftWalletAmount,
      //     paymentSourceId,
      //     firstName,
      //     lastName,
      //     kindtapCustomerId,
      //     debitSelectedCard
      //   },
      //   cartData: {
      //     is_super_or_verified: isSuperOrVerified,
      //     email_saved: emailSaved,
      //     email_required: emailRequired,
      //     totalDiscountApplied,
      //     totalFeesIncluded,
      //     amount,
      //     deliveryCharges,
      //     discountedTaxAmountWithFees,
      //     is_card_verified: isCardVerified,
      //     cart_items: cartItems = [],
      //     name: serverName,
      //     hypurEnabled,
      //     createStrongHold,
      //     createKindtap,
      //     strongHoldEnabled,
      //     appliedGiftCardWallet,
      //     appliedWallet,
      //     hypur_blocked: hypurBlocked,
      //     kindtapEnabled,
      //     calculatedAmount,
      //     is_happy_hours_code_applied
      //   } = {},
      //   ledgerGreenCardData
      // },
      // checkOutUpdateDetails: { customerDetails, deliveryDetails, paymentDetails, deliveryType },
      // stronholdTokenData
    }
  } = useContext(appContext);
  const { checkOutUpdateDetails: { customerDetails, deliveryDetails, paymentDetails, deliveryType }, stronholdTokenData, userID = {} } = useSelector(state => state.other)
  const {
    location,
    couponData,
    isLoggedIn,
    deliveryDetails: {
      deliveryTypeSelected,
      deliveryDate,
      deliveryTime,
      deliveryDateTime,
      address,
      latitude,
      longitude,
      postcode,
      state,
      city,
      country
    },
    smsCheckbox,
    smsMessage,
    userInfo: { phone, country: country_code } } = useSelector(state => state.user)
  const {
    updatingProductsList,
    cartDetails,
    checkoutPossible,
    complianceFee,
    ledgerGreenCardData,
    customerData: {
      name: cartName,
      first_name,
      last_name,
      email,
      isNewCard,
      isLedgerGreenNewCard,
      ledgerGreenSelectedCard,
      paymentType,
      pay_with_card,
      cardData,
      notes,
      useWalletAmount,
      useGiftWalletAmount,
      paymentSourceId,
      firstName,
      lastName,
      kindtapCustomerId,
      debitSelectedCard
    } = {},

    cartData: {
      is_super_or_verified: isSuperOrVerified,
      email_saved: emailSaved,
      email_required: emailRequired,
      totalDiscountApplied,
      totalFeesIncluded,
      amount,
      deliveryCharges,
      discountedTaxAmountWithFees,
      is_card_verified: isCardVerified,
      cart_items: cartItems = [],
      name: serverName,
      hypurEnabled,
      createStrongHold,
      createKindtap,
      strongHoldEnabled,
      appliedGiftCardWallet,
      appliedWallet,
      hypur_blocked: hypurBlocked,
      kindtapEnabled,
      calculatedAmount,
      is_happy_hours_code_applied
    } = {},
  } = useSelector(state => state.cart) || {}
  // const userID = useSelector(state => state.other.userID) || {}
  const appConfig = useConfigData();
  const dispatchRedux = useDispatch()

  const {
    checkProductAvailability,
    createStrongholdAccount,
    saveCardDetail,
    createKindTapAccount,
    setDefaultCard: callSetDefaultCard
  } = useAPI();

  const { data: strongholdData, mutate: mutateStrongholdList } = useStrongholdList();

  const { data: kindtapData, mutate: mutateKindTapList } = useKindTapList();

  const { data: hypurRedirectUrl } = useHyperURL();

  const { trackEvent, identifyEvent } = useSegment();

  const { checkout: callCheckout } = useSocket();

  const {
    data: { upsellZone }
  } = useUpsellProducts();

  const [isHypur, setIsHypur] = useState(false);
  const [isLedgerGreen, setIsLedgerGreen] = useState(false);
  const [ledgerPayment, setLedgerPayment] = useState(false);

  const {
    query: { giftcard }
  } = Router;

  const { data: cardList, mutate: mutateCardList } = useCardList({ payment: ledgerPayment });

  useEffect(() => {
    return () => {
      setLedgerPayment(false);
    };
  }, []);

  const {
    data: {
      is_id_verified: isIdVerified,
      image_url: idImageURL,
      selfie_url: selfieURL,
      licence_back_url: licenceBackUrl,
      sms_disclaimer_checkbox: smsDisclaimerCheckbox
    }
  } = useIdProof();

  const {
    data: { deliveryDays, noScheduleSlotsMessage, locationId, happyHours }
  } = useDeliveryDetails();

  const { is_enable: is_happy_hours_enabled } = happyHours || {};

  const { data: hexAsapDeliveryDetails } = useHexAsapDeliveryDetails(locationId, [postcode, latitude, longitude], {
    refreshInterval: 300000,
    // refreshWhenHidden: true,
    revalidateOnMount: true,
    revalidateOnFocus: false
  });

  const { concentratesWeightPercentage, nonConcentratesWeightPercentage } = useCartConcentration();

  const isCartBeyondLimits = concentratesWeightPercentage > 100 || nonConcentratesWeightPercentage > 100;

  const noCards = !cardList || !cardList.length;

  const areCartItemsPresent = cartItems && cartItems.length;

  const recipientDetails = StorageService.getRecipientData();

  if (!areCartItemsPresent && buttonText === 'Checkout') {
    return null;
  }

  const bringItDisabled =
    !areCartItemsPresent ||
    updatingProductsList.length ||
    (noScheduleSlotsMessage && deliveryTypeSelected === SCHEDULE_TYPE_FUTURE) ||
    !checkoutPossible;

  const giftButtonDisable = !strongHoldEnabled && hypurBlocked && !kindtapEnabled;

  const name = cartName && cartName.length ? cartName : serverName;
  const serverFirstName = serverName
    ? serverName.substr(0, serverName.indexOf(' ')) === ''
      ? serverName
      : serverName.substr(0, serverName.indexOf(' '))
    : null;
  const serverLastName = serverName
    ? serverName.substr(0, serverName.indexOf(' ')) === ''
      ? ''
      : serverName.substr(serverName.indexOf(' ') + 1)
    : null;
  const userFirstName = first_name && first_name.length ? first_name : serverFirstName;
  const userlastName = last_name && last_name.length ? last_name : serverLastName;
  const token = cookie.get(COOKIE_VARIABLES.token) || '';

  async function isValidCartData() {
    if (!isLoggedIn) {
      toast.error('Please verify your mobile number');
      // dispatch({ type: 'setCustomerInfo', payload: { phoneError: 'Please verify your mobile number' } });
      dispatchRedux(setCustomerInfo({ phoneError: 'Please verify your mobile number' }))
      document.getElementById('customer-phone') && document.getElementById('customer-phone').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    const first_nameError = nameValidationWithError(userFirstName, 'First Name');
    if (first_nameError) {
      toast.error(first_nameError);
      // dispatch({ type: 'setCustomerInfo', payload: { first_nameError } });
      dispatchRedux(setCustomerInfo({ first_nameError }))
      document.getElementById('customer-name').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }
    const last_nameError = nameValidationWithError(userlastName, 'Last Name');
    if (last_nameError) {
      toast.error(last_nameError);
      // dispatch({ type: 'setCustomerInfo', payload: { last_nameError } });
      dispatchRedux(setCustomerInfo({ last_nameError }))
      document.getElementById('customer-name').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    let variation = SITE_TYPE.ORIGINAL;
    // if (appConfig.OPTIMIZE_EXPERMENT_ID) {
    //   if (!window.google_optimize) {
    //     try {
    //       await loadScript({ url: `https://www.googleoptimize.com/optimize.js?id=${appConfig.OPTIMIZE_ID}` });
    //     } catch (err) {
    //       setRollbarWarning('BringItButton.js_isValidCartData_optimize_experiment_id', err);
    //       console.error('BringItButton.js_isValidCartData_optimize_experiment_id', err);
    //     }
    //   }
    //   // eslint-disable-next-line no-undef
    //   variation = (window.google_optimize && window.google_optimize.get('9nZLH2CpRyWTxowI9ijQvQ')) || SITE_TYPE.ORIGINAL;
    // }
    // variation = SITE_TYPE.NEW;
    if (variation != SITE_TYPE.NEW) {
      variation = SITE_TYPE.NEW;
      if (
        (emailRequired && !email && !emailSaved) ||
        (email && email.length > 0) ||
        (variation == SITE_TYPE.NEW && !emailSaved)
      ) {
        const emailError = emailValidationWithError(email);

        if (emailError) {
          // dispatch({ type: 'setCustomerInfo', payload: { emailError } });
          dispatchRedux(setCustomerInfo({ emailError }))
          document.getElementById('customer-email').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
          return false;
        }
      }
    }

    // const firstNameError = nameValidationWithError(firstName, 'First Name');
    // const lastNameError = nameValidationWithError(lastName, 'Last Name');
    // if (paymentType == STRONGHOLD && createStrongHold) {
    //   if (firstNameError) {
    //     toast.error(firstNameError);
    //     dispatch({ type: 'setCustomerInfo', payload: { firstNameError } });
    //     document.getElementById('customer-first-name').scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'center',
    //       inline: 'center'
    //     });
    //     return false;
    //   }
    //   if (lastNameError) {
    //     toast.error(lastNameError);
    //     dispatch({ type: 'setCustomerInfo', payload: { lastNameError } });
    //     document.getElementById('customer-last-name').scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'center',
    //       inline: 'center'
    //     });
    //     return false;
    //   }
    // }

    if (deliveryTypeSelected === SCHEDULE_TYPE_FUTURE && deliveryDateTime === DEFAULT_DATETIME && !giftcard) {
      const dateTimeError = 'Please Select Date and Time of Delivery';
      toast.error(dateTimeError);
      // dispatch({ type: 'setCustomerInfo', payload: { dateTimeError } });
      dispatchRedux(setCustomerInfo({ dateTimeError }))
      document.getElementById('delivery-date-time').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (!isIdVerified && (!idImageURL || !selfieURL) && !giftcard) {
      if (!idImageURL) {
        toast.error('please upload your ID');
      } else if (!selfieURL) {
        toast.error('please upload your selfie');
      }

      document.getElementById('cart-id-upload').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });

      return false;
    }

    const localAddress = StorageService.getDeliveryDetails();
    if (localAddress.address === DEFAULT_ADDRESS && !giftcard) {
      const addressError = 'Please add precise address';
      toast.error(addressError);
      // dispatch({ type: 'setCustomerInfo', payload: { addressError } });
      dispatchRedux(setCustomerInfo({ addressError }))
      document.getElementById('checkout-address-block').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (paymentType == CARD && isNewCard && cardData && !cardData.newCardChecked) {
      toast.error('Please enter Debit Card details');
      document.getElementById('debit-card-view').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (
      (paymentType == LEDGERGREEN && isLedgerGreenNewCard && ledgerGreenCardData && !ledgerGreenCardData.newCardChecked) ||
      (paymentType == LEDGERGREEN && !cardList.length)
    ) {
      toast.error('Please enter the card details');
      document.getElementById('ledger-greeen-view').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (paymentType == LEDGERGREEN && !licenceBackUrl) {
      toast.error("Please upload the back of the driver's license");
      document.getElementById('ledger-greeen-view').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (isCartBeyondLimits) {
      toast.error('You have exceeded Daily limit set by DCC. Please remove some products.');
      document.getElementById('cart-concentrates-section').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }
    // if (Number(paymentType) === 2 && (isNewCard || noCards) && !giftcard) {
    //   const cardDataValid = validateCardData();
    //   if (!cardDataValid) {
    //     document.getElementById('customer-card-details').scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'center',
    //       inline: 'center'
    //     });
    //     return false;
    //   }
    // }

    return true;
  }

  async function checkout(paymentData = null) {
    try {
      const { id: couponID } = couponData;
      const defaultCard = debitSelectedCard;
      const defaultCardID = defaultCard ? defaultCard.user_card_id : null;
      const defaultCardNumber = defaultCard ? defaultCard.card_number : null;
      const { driverTip = 0, automaticTipAmount } = cartDetails;
      let dateTime = null;
      let dateTimeLocal = null;
      let endTime = null;

      if (deliveryTypeSelected === SCHEDULE_TYPE_FUTURE && !giftcard) {
        if (deliveryDays && deliveryDays.length) {
          const dateTimeObj = deliveryDays[deliveryDate].deliveryWindows.find(({ startTime }) => startTime === deliveryTime);
          // temp fix
          if (dateTimeObj) {
            dateTime = dateTimeObj.dateTime;
            dateTimeLocal = dateTimeObj.dateTimeLocal;
            endTime = dateTimeObj.endDateTime;
          } else {
            dateTime = deliveryDays[deliveryDate].deliveryWindows[0].dateTime;
            dateTimeLocal = deliveryDays[deliveryDate].deliveryWindows[0].dateTimeLocal;
            endTime = deliveryDays[deliveryDate].deliveryWindows[0].endDateTime;
          }
        } else {
          const error = {
            data: {
              success: false,
              error: { message: 'Error in schedule date' },
              code: 1
            }
          };
          throw new APIException(error);
        }
      } else {
        const date = new Date();
        dateTimeLocal = getFullDate(date);
      }

      const localAddress = StorageService.getDeliveryDetails();
      let otherEmail = StorageService.getTempEmail() || null;
      let firstTime = !!StorageService.getTempEmail();
      const subscriptionDetails = StorageService.getSubscriptionDetails();

      const payment = {
        type: Number(paymentType),
        pay_with_card,
        card_id: isNewCard || noCards ? OTHER_CARD : defaultCardID,
        card_number: paymentType == CARD ? defaultCardNumber : null,
        amount: giftcard ? recipientDetails.amount : totalFeesIncluded,
        driverTip: paymentType === COD ? 0 : driverTip,
        automaticTipAmount: paymentType === COD ? false : automaticTipAmount,
        pac_number: paymentType == HYPUR ? paymentData : null,
        payment_source_id: paymentType == STRONGHOLD ? paymentSourceId : paymentType == KIND_TAP ? kindtapCustomerId : null
      };
      if ((paymentType == STRONGHOLD || paymentType == KIND_TAP) && paymentData) {
        payment.payment_source_id = paymentData;
      }
      if (paymentType == LEDGERGREEN && paymentData) {
        const { cardCVV, cardExp, cardName, cardNumber, saveCard, zipcode } = ledgerGreenCardData;
        const monthAndYear = cardExp?.split('/');
        const month = Number(monthAndYear?.[0]);
        const year = Number(monthAndYear?.[1]);
        const upperCaseCardType = isLedgerGreenNewCard ? getCardType(cardNumber.replace(/ /g, '')) : null;
        const cardType = upperCaseCardType ? upperCaseCardType.toLowerCase() : null;
        payment.lg_payment = {
          signature: paymentData,
          ...(isLedgerGreenNewCard
            ? {
              cardInfo: {
                amount: calculatedAmount,
                postal_code: zipcode,
                card_number: cardNumber.replaceAll(' ', ''),
                card_holder_name: cardName,
                month,
                year,
                cvv: cardCVV,
                save_card: saveCard,
                card_type: cardType
              }
            }
            : { cardInfo: { ...ledgerGreenSelectedCard } }),
          compliance_fee: complianceFee
        };
      }

      const fullName = giftcard && recipientDetails ? recipientDetails.name.split(' ') : null;
      const firstElement = giftcard && fullName ? fullName.shift() : null;
      const data = {
        priority_delivery_selected: deliveryTypeSelected === SCHEDULE_TYPE_ASAP_PRIORITY ? 1 : 0,
        sms_disclaimer_checked: smsMessage ? smsCheckbox : smsDisclaimerCheckbox,
        type: 0,
        total_amount: giftcard ? recipientDetails.amount : totalFeesIncluded,
        customer_name: `${userFirstName.trim()} ${userlastName}`,
        firstname: first_name.trim(),
        lastname: last_name,
        secondary_telephone: '',
        productId: giftcard ? recipientDetails.product_id : null,
        isGiftCard: giftcard ? recipientDetails.isGiftCard : null,
        recipient: giftcard
          ? {
            email: recipientDetails.email,
            image: recipientDetails.productImage,
            countryCode: recipientDetails.country_code,
            phone: recipientDetails.phoneNumber,
            custom_message: recipientDetails.customMessage,
            firstName: firstElement,
            lastName: fullName.join(' ')
          }
          : null,
        address: {
          placeid: localAddress.placeId,
          address_id: 0,
          address1: address,
          latitude,
          longitude,
          postcode,
          state,
          city,
          country
        },
        schedule: {
          type: (deliveryTypeSelected % 10) + 2,
          dateTime,
          dateTimeLocal,
          endTime,
          delivery_surcharge_fee:
            deliveryTypeSelected === SCHEDULE_TYPE_FUTURE
              ? localAddress?.deliveryData?.deliverySurchargeFee
                ? localAddress.deliveryData.deliverySurchargeFee
                : 0
              : 0,
          zone_wise_schedule_windows_id:
            deliveryTypeSelected === SCHEDULE_TYPE_FUTURE
              ? localAddress?.deliveryData?.zoneWiseScheduleWindowsId
                ? localAddress.deliveryData.zoneWiseScheduleWindowsId
                : 0
              : 0,
          schedule_windows_id:
            deliveryTypeSelected === SCHEDULE_TYPE_FUTURE
              ? localAddress?.deliveryData?.scheduleWindowsId
                ? localAddress.deliveryData.scheduleWindowsId
                : 0
              : 0
        },
        extra_notes: notes,
        email,
        payment,
        delivery_token: hexAsapDeliveryDetails?.token || null // gives delivery details based on new routing config
      };

      data.dtc_data = {};
      if (isCheckoutOnly && subscriptionDetails) {
        data.dtc_data = subscriptionDetails || {};
      }

      if (isCheckoutOnly) {
        data.klaviyo_email = otherEmail;
      }

      if (!endTime || endTime === 'Invalid Date') {
        setRollbarWarning('Invalid_end_time', {
          deliveryDays,
          deliveryDate,
          dateTime,
          dateTimeLocal,
          endTime,
          socketData: data
        });
      }

      const products = [];
      const origin = window.location.origin;

      if (cartItems?.length) {
        cartItems.forEach((cartItem, index) => {
          products.push({
            product_id: cartItem.product_id,
            sku: cartItem.product_id,
            name: cartItem.name,
            list: cartItem.list,
            product_attributes: Array.isArray(cartItem.product_attributes_second)
              ? cartItem.product_attributes_second.join('/')
              : '',
            price: cartItem.product_price || cartItem.price,
            original_price: cartItem.price_without_deal || cartItem.price,
            quantity: cartItem.quantity,
            category: cartItem.full_shop_category_name || '',
            category_frontend: cartItem.category_name,
            url: `${origin}/product/${cartItem.slug}`,
            image_url: cartItem.image || cartItem.product_image,
            position: index,
            brand: Array.isArray(cartItem.brand_names) ? cartItem.brand_names.join('/') : cartItem.brand_slug || '',
            strain_type: cartItem.product_strain_type_name || '',
            coupon: couponData.discount_code || '0',
            variant: `${cartItem.category_weight} ${cartItem.category_unit}`
          });
        });
      }

      data.dtc_data = {
        ...(isCheckoutOnly && subscriptionDetails ? subscriptionDetails : {}),
        lastdate: '10/10/2022',
        type: 'daily',
        customer_name: `${userFirstName.trim()} ${userlastName}`,
        customer_first_name: first_name.trim(),
        customer_last_name: last_name,
        customer_address: {
          placeid: localAddress.placeId,
          address_id: 0,
          address1: address,
          latitude,
          longitude,
          postcode,
          state,
          city,
          country
        },
        email,
        total_cart_amount: totalFeesIncluded,
        subtotal: amount,
        payment_method: paymentType,
        cart_items: products,
        ...(token ? { user_token: token } : {})
      };

      if (Number(paymentType) === 2 && (isNewCard || noCards)) {
        const { cardNumber, cardExp, cardCVV, cardName, zipcode, saveCard } = cardData;
        const monthAndYear = cardExp.split('/');
        const month = Number(monthAndYear[0]);
        const year = Number(monthAndYear[1]);
        const upperCaseCardType = getCardType(cardNumber.replace(/ /g, ''));
        const cardType = upperCaseCardType ? upperCaseCardType.toLowerCase() : null;

        data.payment = {
          ...data.payment,
          card_number: cardNumber.replace(/ /g, ''),
          card_month: month < 10 ? `0${month}` : `${month}`,
          card_year: year,
          card_exp: cardExp,
          card_cvv: cardCVV,
          card_name: cardName,
          card_type: cardType,
          card_save: saveCard,
          card_zipcode: zipcode || null,
          card_address: address,
          card_city: city,
          card_state: state,
          card_country: country
        };
      }

      if (useWalletAmount) {
        data.wallet = {
          amount: Number(appliedWallet)
        };
      }

      if (useGiftWalletAmount) {
        data.gift_card_wallet = {
          amount: Number(appliedGiftCardWallet)
        };
      }

      if (couponID) {
        data.coupon = {
          coupon_id: couponID,
          code: couponData.discount_code,
          coupon_type_id: couponData.coupon_type,
          type: couponData.coupon_type,
          discount: couponData.discount,
          user_coupon: couponData.user_coupon
        };
      }

      setIsHypur(false);
      setLoading(true);

      const { data: productAvailabilityData = {} } = !giftcard
        ? await checkProductAvailability({
          isLoggedIn,
          postcode,
          latitude,
          longitude,
          scheduleType: deliveryTypeSelected
        })
        : {};

      if (!giftcard) {
        const { data: { are_all_products_available: areAllProductsAvailable } = {} } = productAvailabilityData;

        if (!areAllProductsAvailable) {
          toast.error("Some products are sold out.")
          throw new Error('Some products sold out');
        }
      }

      const checkoutOnlyIntegrations = {
        integrations: {
          Klaviyo: false
        }
      };

      trackEvent(
        'Checkout Started',
        {
          order_id: '',
          affiliation: `${appConfig.APP_NAME} Online`,
          value: totalFeesIncluded,
          revenue: amount,
          subtotal: amount,
          shipping: deliveryCharges,
          delivery_type: deliveryTypeSelected + 2,
          tax: discountedTaxAmountWithFees,
          discount: totalDiscountApplied,
          coupon: couponData.discount_code || '',
          currency: cartItems?.[0]?.category_currency === '$' ? 'usd' : cartItems?.[0]?.category_currency || 'usd',
          isGiftcard: !!giftcard,
          products
        },
        // isCheckoutOnly ? checkoutOnlyIntegrations : {}
      );

      callPendingEvents({ segmentProducts: products });
      const res = await callCheckout(data, host);

      if (Number(paymentType) === 2 && (isNewCard || noCards) && data.payment.card_save) {
        const payload = {
          card_number: data.payment.card_number,
          card_month: data.payment.card_month,
          card_year: data.payment.card_year,
          card_exp: data.payment.card_exp,
          card_cvv: data.payment.card_cvv,
          card_name: data.payment.card_name,
          card_type: data.payment.card_type,
          card_save: data.payment.card_save,
          card_zipcode: data.payment.card_zipcode,
          card_funding: res.card_funding,
          is_default: false
        };

        saveCardDetail({ ...payload });
      }

      if (paymentType == LEDGERGREEN && isLedgerGreenNewCard && ledgerGreenCardData.saveCard) {
        const { cardCVV, cardExp, cardName, cardNumber, saveCard, zipcode } = ledgerGreenCardData;

        const monthAndYear = cardExp.split('/');
        const month = Number(monthAndYear[0]);
        const year = Number(monthAndYear[1]);
        const upperCaseCardType = getCardType(cardNumber.replace(/ /g, ''));
        const cardType = upperCaseCardType ? upperCaseCardType.toLowerCase() : null;

        const payload = {
          card_number: cardNumber,
          card_month: month,
          card_year: year,
          card_exp: cardExp,
          card_cvv: cardCVV,
          card_name: cardName,
          card_type: cardType,
          card_save: saveCard,
          card_zipcode: zipcode,
          card_funding: res.card_funding,
          type: LEDGERGREEN,
          orderId: giftcard ? res?.order_id?.order_id : res?.order_id,
          is_default: false
        };

        saveCardDetail({ ...payload });
      }

      if (isCheckoutOnly) {
        StorageService.clearTempEmail();
        StorageService.clearUserTempData();
        StorageService.clearSubscriptionDetails();

        otherEmail = null;

        if (firstTime) {
          dispatch({
            type: 'callOrderMadeSegment',
            payload: {
              order_id: res.order_id,
              affiliation: `${appConfig.APP_NAME} Online`,
              zone_name: upsellZone,
              value: totalFeesIncluded,
              revenue: amount,
              subtotal: amount,
              shipping: deliveryCharges,
              delivery_type: deliveryTypeSelected + 2,
              tax: discountedTaxAmountWithFees,
              discount: totalDiscountApplied,
              coupon: couponData.discount_code || '',
              currency: cartItems && cartItems.length ? cartItems[0].category_currency : '$',
              shipping_method: deliveryTypeSelected + 2,
              payment_method: paymentType,
              products,
              email
            }
          });
        }
      }

      if (!isCheckoutOnly || (isCheckoutOnly && !firstTime)) {
        const purchaseDataLayer = {
          order_id: res.order_id,
          affiliation: `${appConfig.APP_NAME} Online`,
          zone_name: upsellZone,
          value: totalFeesIncluded,
          revenue: amount,
          subtotal: amount,
          shipping: deliveryCharges,
          delivery_type: deliveryTypeSelected ? `${deliveryTypeSelected + 2}` : '0',
          tax: discountedTaxAmountWithFees,
          discount: totalDiscountApplied,
          coupon: couponData.discount_code || '',
          currency: cartItems?.[0]?.category_currency === '$' ? 'usd' : cartItems?.[0]?.category_currency || 'usd',
          shipping_method: deliveryTypeSelected ? deliveryTypeSelected + 2 : 0,
          payment_method: paymentType,
          products,
          with_happy_hours_availed: is_happy_hours_code_applied,
          happy_hours_active: is_happy_hours_enabled
        };
        if (process.env.NEXT_PUBLIC_APP === KIVA) {
          const kivaObject = {
            name: `${first_name} ${last_name}`,
            email: otherEmail || userID.customer_email || email,
            city,
            state,
            zipcode: postcode,
            phone: `+${country_code}${phone}`
          };
          const purchaseDataLayerKiva = { ...purchaseDataLayer, ...kivaObject };
          trackEvent('purchase', purchaseDataLayerKiva);
        }
        trackEvent('Order Made', purchaseDataLayer);
      }

      identifyEvent(
        userID.customer_id,
        {
          location_name: location === 1 ? 'LA' : 'SF',
          email: otherEmail || userID.customer_email || email
        },
        isCheckoutOnly ? checkoutOnlyIntegrations : {}
      );

      firstTime = false;

      if (res?.order_id) {
        // dispatch({ type: 'setCustomerInfo', payload: { checkedDebitCard: null, checkedLGCard: null } });
        dispatchRedux(setCustomerInfo({ checkedDebitCard: null, checkedLGCard: null }))
        // dispatch({ type: 'setCartData', payload: { cartData: null } });
        dispatchRedux(setCartData({ cartData: null }))
        StorageService.removeItemSession(isScheduleDropDownSet);
        setLedgerPayment(LEDGERGREEN);
        // dispatch({ type: 'setLedgerGreenCardData', payload: { ...defaultCardData } });
        dispatchRedux(setLedgerGreenCardData({ ...defaultCardData }))
        StorageService.removeLedgerGreenCardSession();
        // dispatch({ type: 'setCustomerInfo', payload: { cardData: { ...defaultCardData } } });
        dispatchRedux(setCustomerInfo({ cardData: { ...defaultCardData } }))
        StorageService.removeDebitCardSession();
        mutateCardList();
      }
    } catch (error) {
      setRollbarWarning('BringItButton.js_checkout_catch', error);
      console.error('BringItButton.js_checkout_catch', error);
    } finally {
      setLoading(false);
    }
  }

  const createStrongholdCustomer = async () => {
    return createStrongholdAccount({ firstName: userFirstName, lastName: userlastName });
  };

  const debouncedCreateStrongholdAccount = useCallback(
    debounce(createStrongholdCustomer, 2000, {
      leading: true,
      trailing: false
    }),
    [userFirstName, userlastName]
  );

  async function bringIt() {
    setCheckingOut(true);
    if (pathTo) {
      try {
        Router.push(pathTo);
      } catch (error) {
        setRollbarWarning('BringItButton.js_bringIt_catch', error);
        console.error('BringItButton.js_bringIt_catch', error);
      }
    } else {
      const validCartData = await isValidCartData();
      if (validCartData) {
        StorageService.setUserData({
          ...(StorageService.getUserData() || {}),
          name: `${userFirstName} ${userlastName}`,
          first_name: userFirstName,
          last_name: userlastName
        });
        // dispatch({ type: 'setCustomerInfo', payload: { firstName: userFirstName, lastName: userlastName } });
        dispatchRedux(setCustomerInfo({ firstName: userFirstName, lastName: userlastName }))
        if (paymentType == HYPUR) {
          if (!hypurEnabled) {
            window.open(hypurRedirectUrl, '_blank');
          } else {
            setIsHypur(true);
          }
        } else if (paymentType == STRONGHOLD) {
          const strongholdPay = window.Stronghold.Pay({
            publishableKey: appConfig?.STRONGHOLD_PUBLISHABLE?.[locationId],
            environment: process.env.NEXT_PUBLIC_ENV != 'production' ? 'sandbox' : 'live'
          });
          if (createStrongHold) {
            try {
              setLoading(true);
              const token = await debouncedCreateStrongholdAccount();
              strongholdPay.addPaymentSource(token, {
                onReady: () => {
                  setLoading(false);
                },
                onSuccess: paymentSource => {
                  mutateStrongholdList();
                  const { id } = paymentSource;
                  checkout(id);
                },
                onExit: () => { },
                onError: err => {
                  setLoading(false);
                  const {
                    error: { message = '' }
                  } = err;
                  setCheckingOut(false);
                  toast.error(message);
                }
              });
            } catch (err) {
              setLoading(false);
            }
          } else if (strongholdData && strongholdData.length > 0) {
            checkout();
          } else {
            const { token } = stronholdTokenData;
            setLoading(true);
            strongholdPay.addPaymentSource(token, {
              onReady: () => {
                setLoading(false);
              },
              onSuccess: paymentSource => {
                mutateStrongholdList();
                const { id } = paymentSource;
                checkout(id);
              },
              onExit: () => { },
              onError: err => {
                setLoading(false);
                const {
                  error: { message = '' }
                } = err;
                toast.error(message);
              }
            });
          }
        } else if (paymentType == KIND_TAP) {
          setLoading(true);
          if (createKindtap) {
            window.KindTap.signup(
              {
                companyName: isCheckoutOnly
                  ? envAppConfig?.[host]?.APP_NAME || envAppConfig?.grassdoor?.APP_NAME
                  : appConfig.APP_NAME,
                email,
                firstName: first_name,
                lastName: last_name,
                phone,
                address1: address,
                zip: postcode,
                state,
                city
              },
              async function (success) {
                const response = await createKindTapAccount(success.token);
                const { id } = response;
                if (response) {
                  mutateKindTapList();
                  checkout(id);
                  setLoading(false);
                }
              },
              function (error) {
                setCheckingOut(false);
                setLoading(false);
                toast.error(error.error);
              },
              function () {
                setLoading(false);
              }
            );
          } else {
            checkout();
          }
        } else if (paymentType == LEDGERGREEN) {
          setIsLedgerGreen(true);
        } else if (paymentType == SWIFTER) {
          setLoading(true)
          try {
            let data = await getSessionID({
              consumer_info: {
                first_name: firstName,
                last_name: lastName
              },
              amount: amount
            })
            if (data.id) {
              const sp = new SwifterPay();
              sp.startSession({
                sessionID: data.id,
                selector: 'body',
                renderInModal: true,
                onComplete: (response) => {
                  process.env.NEXT_PUBLIC_ENV === "development" && console.log("payment response", response);
                  checkout();
                  // Called on successful session completion
                },
                onExit: (response) => {
                  process.env.NEXT_PUBLIC_ENV === "development" && console.log("payment response exit", response, sessionID);
                  // Called on a failed session
                  toast.error("Payment failed")
                }
              });
            } else {
              toast.error("Payment failed")
            }
          } catch (error) {
            return
          } finally {
            setLoading(false)
          }
        } else {
          checkout();
        }
      }
    }
  }

  const callPendingEvents = ({ segmentProducts }) => {
    const userInfo = StorageService.getUserData() || {};
    if (!customerDetails) {
      trackEvent('Checkout Step Viewed', {
        checkout_id: userInfo.phone,
        step: 1,
        shipping_method: '0',
        payment_method: '0'
      });

      trackEvent('Checkout Step Completed', {
        checkout_id: userInfo.phone,
        step: 1,
        shipping_method: '0',
        payment_method: '0'
      });
    }

    if (!deliveryType) {
      trackEvent('Checkout Step Viewed', {
        checkout_id: userInfo.phone,
        step: 2,
        shipping_method: '0',
        payment_method: '0'
      });

      trackEvent('Checkout Step Completed', {
        checkout_id: userInfo.phone,
        step: 2,
        shipping_method: '0',
        payment_method: '0'
      });
    }

    if (!paymentDetails) {
      trackEvent('Payment Info Entered', {
        checkout_id: userInfo.phone,
        order_id: userInfo.phone,
        step: 3,
        shipping_method: deliveryTypeSelected ? `${deliveryTypeSelected + 2}` : '0',
        payment_method: paymentType,
        currency: cartItems?.[0]?.category_currency === '$' ? 'usd' : cartItems?.[0]?.category_currency || 'usd',
        products: segmentProducts
      });

      trackEvent('Checkout Started', {
        checkout_id: userInfo.phone,
        step: 3,
        shipping_method: deliveryTypeSelected ? `${deliveryTypeSelected + 2}` : '0',
        payment_method: paymentType,
        products: segmentProducts
      });

      trackEvent('Checkout Step Completed', {
        checkout_id: userInfo.phone,
        step: 3,
        shipping_method: deliveryTypeSelected ? `${deliveryTypeSelected + 2}` : '0',
        payment_method: paymentType
      });
    }
  };

  function dismissHypur() {
    setIsHypur(false);
  }

  return (
    <Fragment>
      <button
        disabled={giftcard ? giftButtonDisable : bringItDisabled}
        type="button"
        onClick={bringIt}
        className="btn btn-primary bold btn-block rouded-button"
        data-testid="#cart_bring_it_btn"
        test-id="#cart_bring_it_btn"
      >
        {giftcard ? 'Send Gift Card' : buttonText}
      </button>

      {isHypur && <HypurVerificationPopup checkout={checkout} dismiss={dismissHypur} />}

      {isLedgerGreen && (
        <LedgerGreenModal>
          <LedgerGreenVerificationPopup
            checkout={checkout}
            dismiss={() => setIsLedgerGreen(false)}
            amount={calculatedAmount}
            setLoading={setLoading}
          />
        </LedgerGreenModal>
      )}
    </Fragment>
  );
};

BringItButton.defaultProps = {
  buttonText: 'Order Now'
};

export default BringItButton;
